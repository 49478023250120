#GameBoard {
    position: relative;
    margin: auto;
    border-style: solid;
    overflow: hidden;
}

#GameBoard .Block {
    position: absolute;
    -webkit-box-shadow: 0px 0px 18px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 0px 0px 18px 6px rgba(0, 0, 0, 0.28);
}

#GameBoard #GameOver {
    text-align: center;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
}

#GameBoard #Score {
    position: relative;
    top: 105%;
    text-align: center;
    font-weight: bold;
}

#GameBoard #GameOverText {
    color: #ca0000;
    font-weight: bold;
    text-decoration-line: underline;
}

#GameBoard #PressSpaceText {
    color: #2e7ecb;
    font-weight: bold;
}
